export const aboutCopy = {
  p1: 'This train-detection application was built by the team from ',
  p1LinkText: 'DevelopmentNow',
  p1p2: ', a Portland development agency specializing in innovative software that solves problems.',
  p2: 'Drivers in Portland, OR generally don’t use their horns. At Southeast 11th and Division, however, it regularly sounds like rush-hour gridlock in Manhattan when trains block traffic. This project was born from a desire to fix this problem—or at least help drivers circumnavigate it. Reducing traffic congestion lowers emissions, reduces noise, improves productivity, gives people time back in their day, and makes everyone a little happier.',
  p3: 'The trains that run across Southeast 11th Avenue (or Milwaukie Avenue, depending who you ask) cause delays and traffic back-ups at 11th and Division. The people behind this application work in an office with a birds-eye view of all this, and we figured we could use our vantage point to everyone’s advantage.',
  p4: 'We used AI/machine learning, vision processing, predictive heuristics, and off-the-shelf hardware to build a cloud-based prototype of this application. After a bit of tweaking and testing, the application tells us with reasonable accuracy whether a train is blocking the intersection outside our window. We hope you can use this application to avoid the two-hour traffic jams at Southeast 11th and Division, because, yes, those actually happen.',
  p5: 'We’re constantly improving and updating this application and would love your feedback. Feel free to email us at:',
  contactEmail: 'ohgodnotagain@isatrainblocking11th.com'
}

export const legalCopy = {
  p1: 'Even though this application is about traffic, we still want you to be smart and safe. You acknowledge that it is your responsibility to comply with all applicable laws (including traffic laws) while using the application.',
  p2: 'This application and its data is copyrighted. You may not download, scrape, store, reverse-engineer, copy, modify, or use this application or its data or components for any other use, personal or commercial.',
  p3: 'This application is in beta, and as such, may have errors, bugs, or issues with operation. We are not liable for any issues you may have with the application.',
  p4: 'You agree and acknowledge that you assume full, exclusive and sole responsibility for the use of and reliance on the application, and you further agree and acknowledge that your use of or reliance on the application is made entirely at your own risk.',
  p5: 'This application, including its owners, officers, directors, employees, sub-contractors, and agents, will not be liable for any direct, indirect, incidental or consequential damage, or any other damage, injury, loss of life, legal or criminal consequences, loss, costs, expenses, or any other form of liability arising from, or in connection with the proper or improper use of or inability to use this application, or from any fault, or error made by our staff or anyone acting on its behalf, or from your use of or reliance on the application or its content.'

}