import React from 'react'
import styled from 'styled-components'
import { Link } from "gatsby"

export const Footer = props => {
  return (
    <FooterContainer background={props.backgroundColor}>  
      <FooterText>
        <Link to="/about/" state={{trainStatus: props.trainStatus}} style={{ textDecoration: 'none', color: '#565656' }}>ABOUT</Link>
      </FooterText>
      <FooterText style={{ textDecoration: 'none', color: '#565656' }}>|</FooterText>
      <FooterText>
        <Link to="/" style={{ textDecoration: 'none', color: '#565656' }}>TRAIN</Link>
      </FooterText> 
      <FooterText style={{ textDecoration: 'none', color: '#565656' }}>|</FooterText>
      <FooterText>
        <Link to="/legal/" style={{ textDecoration: 'none', color: '#565656' }}>LEGAL</Link>
      </FooterText>
    </FooterContainer>
  ) 
} 

const FooterContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr .3fr 1fr .3fr 1fr;
  width: 100%;
  justify-items: center;
  opacity: 1;
  background-color: ${({background}) => background}
`

const FooterText = styled.p`
  font-family: Aktiv Grotesk, sans-serif;
  font-weight: 500;
  font-size: 13px;
  line-height: 17px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.15em; 
`

